/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import Moment from 'moment';
//import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";

export class OccurrenceRow extends Component {

  render() {
    const styles = {
      tr: {
        cursor: 'pointer'
      }
    }

    const row_title = this.props.tasksData;
    var row = [];
    for (var i = 0; i < row_title.length; i++) {
      const id = row_title[i].id;
      row.push(
        <tr style={styles.tr} key={i} onClick = {(event) => {this.props.onSelect(event, id)}}>
          <td>{Moment(new Date(row_title[i].scannedAt)).format("DD/MM/YYYY HH:mm")}</td>
          <td>{row_title[i].label}</td>
        </tr>
      );
    }
    return <tbody>{row}</tbody>;
  }
}

export default OccurrenceRow;
