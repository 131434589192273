import React, { Component } from 'react';
import Marker from '../../components/Maps/Marker';
import GoogleMap from '../../components/Maps/GoogleMap';

import Axios, { apiRoutes } from '../../Axios';
import AuthContext from 'context/Auth';
import LoadingOverlay from "react-loading-overlay";

class Traceability extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    occurrences: [],
    error: '',
    googleMaps: null,
    mapInstance: null,
    listener: null,
    isLoading: true
  };

  componentDidMount() {
    Axios.get(apiRoutes.getDashboard, {
      params: {
        companyId: this.context.getCompanyId()
      }
    }).then(response => {
      this.setState({
        occurrences: response.data.lastestOccurrences,
        isLoading: false
      });
    }).catch(error => {
      this.setState({ error: error.response.data.message || '', isLoading: false});
    });
  }


  getMapBounds = (map, maps) => {   //Obter bound apartir dos locais marcados
    const bounds = new maps.LatLngBounds();
    this.state.occurrences.forEach(occurrence => {
      bounds.extend(new maps.LatLng(
        occurrence.locationLat,
        occurrence.locationLong,
      ));
    });
    return bounds;
  };

  componentWillUnmount() {  //Remover os listeners do Googlemaps(Singleton) quando sair
    if (this.state.googleMaps) {
      this.state.googleMaps.event.removeListener(this.state.listener);
    }
  }
  
  componentDidUpdate() {  //Quando atualiza a página ou adicionar mais markers fitbounds no mapa
    if(this.state.mapInstance) {
      const bounds = this.getMapBounds(this.state.mapInstance, this.state.googleMaps);
      if (this.state.occurrences.length > 0) {
        this.state.mapInstance.fitBounds(bounds);
      }
    }
  }

  apiIsLoaded = (map, maps) => { //Centralizar o mapa
    this.setState({ googleMaps: maps, mapInstance: map });
    //Listener para quando redimensionar a tela, centralizar o mapa
    const listener = maps.event.addDomListener(window, 'resize', () => {
      if (this.state.occurrences.length > 0) {
        const bounds = this.getMapBounds(map, maps)
        map.fitBounds(bounds);
      }
    });
    this.setState({ listener });
  };

  render() {
    const ErrorElement = this.state.error.length > 0 ? <p style={{ color: 'red' }}>{this.state.error}</p> : null;
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Carregando...">
      <section style={{ width: '100%', height: '100vh' }}>
        {ErrorElement}
        <GoogleMap
          defaultZoom={4.9}
          defaultCenter={[-15.188657, -54.2979841]}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => this.apiIsLoaded(map, maps)}
        >
          {this.state.occurrences.map(place => (
            <Marker
              key={place.id}
              text={'ggg'}
              lat={place.locationLat}
              lng={place.locationLong}
            />))
          }
        </GoogleMap>
      </section>
      </LoadingOverlay>
    );
  }
}

export default Traceability;