import React, { Component } from "react";
import {
  Grid,
  Row,
  Col
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import avatar from "assets/img/default-avatar.png";
import Axios, { apiRoutes } from '../../Axios';
import userBackgroundImage from '../../assets/img/user_background.jpg';
import moment from 'moment';
import AuthContext from 'context/Auth';
import LoadingOverlay from "react-loading-overlay";

class UserDetails extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  constructor(props, context) {
    super(props, context);
    this.browseElement = React.createRef();
    const isEditing = this.props.match.path === "/admin/user/:id"

    this.state = {
      imageBlob: '',
      isLoading: isEditing,
      imageFile: null,
      didPasswordChanged: false,
      error: "",
      isEditing: isEditing,
      userId: this.props.match.params.id,
      inputs: {
        companyId: {
          label: "Empresa",
          type: "dropdown",
          values: [],
          inputvalue: "",
          error: "",
          onItemSelected: (event) => this.onCompanySelected(event)
        },
        fullName: {
          label: "Nome Completo",
          type: "text",
          bsClass: "form-control",
          placeholder: "Nome completo",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'fullName')
        },
        birthday: {
          label: "Data de Nascimento",
          type: "text",
          bsClass: "form-control",
          placeholder: "DD/MM/YYYY",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'birthday')
        },
        rg: {
          label: "RG",
          type: "text",
          bsClass: "form-control",
          placeholder: "RG",
          inputvalue: "",
          error: "",
          disabled: isEditing,
          onChange: (event) => this.onInputChangedHandler(event, 'rg')
        },
        cpf: {
          label: "CPF",
          type: "text",
          bsClass: "form-control",
          placeholder: "CPF",
          inputvalue: "",
          error: "",
          disabled: isEditing,
          onChange: (event) => this.onInputChangedHandler(event, 'cpf')
        },
        tel: {
          label: "Telefone",
          type: "text",
          bsClass: "form-control",
          placeholder: "(dd) xxxxx-xxxx",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'tel')
        },
        email: {
          label: "Email",
          type: "email",
          bsClass: "form-control",
          placeholder: "Email",
          inputvalue: "",
          disabled: isEditing,
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'email')
        },
        userRoleId: {
          label: "Função",
          type: "dropdown",
          inputvalue: "",
          values:  [{id: 1, name: 'Operador'}, 
                   {id: 2, name: 'Administrador'}],
          error: "",
          onItemSelected: event => this.onUserRoleSelected(event)
        },
        password: {
          label: isEditing ? "Nova Senha (Opcional)" : "Senha",
          type: "password",
          bsClass: "form-control",
          placeholder: isEditing ? "***********************************" : "Senha",
          inputvalue: '',
          error: "",
          onChange: (event) => {
            this.onInputChangedHandler(event, 'password');
          }
        },
        confirmPassword: {
          label: isEditing ? "Confirmar Senha (Opcional)" : "Confirmar Senha",
          type: "password",
          bsClass: "form-control",
          placeholder: isEditing ? "***********************************" : "Confirmar Senha",
          inputvalue: '',
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'confirmPassword')
        },
        address: {
          label: "Endereço",
          type: "text",
          bsClass: "form-control",
          placeholder: "Rua José Paulino, 615",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'address')
        },
        cep: {
          label: "CEP",
          type: "text",
          bsClass: "form-control",
          placeholder: "CEP",
          error: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'cep')
        },
        city: {
          label: "Cidade",
          type: "text",
          bsClass: "form-control",
          placeholder: "Cidade",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'city')
        },
        neighborhood: {
          label: "Bairro",
          type: "text",
          bsClass: "form-control",
          placeholder: "Bairro",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'neighborhood')
        },
        state: {
          label: "Estado",
          type: "text",
          bsClass: "form-control",
          placeholder: "Estado",
          inputvalue: "",
          error: "",
          onChange: (event) => this.onInputChangedHandler(event, 'state')
        }
      }
    }
  }

  componentDidMount() {
    const inputs = {...this.state.inputs};
    Axios.get(apiRoutes.getCompaniesList, {params:{companyId: -1, pagination: false}}) //Obter todas as empresas, o backend retornará somente a empresa atual se não for usuário ciclopack
         .then(result => {   
            const companyIdCheckbox = {...inputs.companyId};
            companyIdCheckbox.values = result.data;
            inputs.companyId = companyIdCheckbox;
            this.setState({inputs, isLoading: this.state.isEditing}); //Parar aqui se não estiver editando
            if (this.state.isEditing) {
              return Axios.get(apiRoutes.getUserDetails, {
                params: {
                  userId: this.state.userId
                }
              });
            } 
         }).then(response => {
           if(!response) return;
           response.data.birthday = moment(new Date(response.data.birthday)).format("DD/MM/YYYY")
           for(var key in response.data) {
             if(this.state.inputs[key]) {
                const input = {...inputs[key]};
                input.inputvalue = response.data[key];
                inputs[key] = input;
             }
           }
           //Setar estado antes de retornar um promise
           this.setState({inputs, isLoading: false});   //Setar informações em linha diferente da imagem, caso não encontra a imagem do usuário
           if (this.state.isEditing) {
             return Axios.get(apiRoutes.getUserImage, {
               responseType: 'arraybuffer',
               params: {
                 userId: this.state.userId
               }
             })
           }
         }).then(imageResponse => {
            let image = this.state.imageBlob;
            if (imageResponse && imageResponse.data) {
              const base64 = btoa(
                new Uint8Array(imageResponse.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  '',
                ),
              );
              image = "data:;base64," + base64;
            }
            this.setState({imageBlob: image});
         }).catch(error => {
           this.setState({error: error.response.data.message || ''});
         })
  }

  onCompanySelected = (index) => {
    const inputs = this.state.inputs;
    const companyCheckbox = {...inputs.companyId};

    companyCheckbox.inputvalue = companyCheckbox.values[index].id;
    companyCheckbox.error = "";
    
    inputs.companyId = companyCheckbox;
    this.setState({inputs});
  }

  onUserRoleSelected = index => {
    const inputs = this.state.inputs;
    const userRoleCheckbox = {...inputs.userRoleId};
    userRoleCheckbox.inputvalue = userRoleCheckbox.values[index].id;
    userRoleCheckbox.error = "";
    inputs.userRoleId = userRoleCheckbox;
    this.setState({inputs});
  }

  onInputChangedHandler = (event, inputName) => {
    const updatedStates = { ...this.state };
    updatedStates.inputs[inputName].inputvalue = event.target.value;
    updatedStates.inputs[inputName].error = "";  //limpar o erro anterior quando edita o campo
    this.setState(updatedStates);
    if(event.target.type ==='password') {
      this.setState({didPasswordChanged: event.target.value.length > 0}); 
    }
  }

  selectImageHandler = (event) => {
    this.browseElement.click();
  }
  onFileSelected = (event) => {
    event.stopPropagation();
    event.preventDefault();
    var file = event.target.files[0];
    if(file) {
      this.setState({imageBlob: URL.createObjectURL(file), imageFile: file});
    }
  }

  handleErrors(error) {
    const inputsValidationErrors = error.response.data.errors;
    const errorResponse = error.response.data.message;
    if (inputsValidationErrors && inputsValidationErrors.length > 0) {
      const updatedStates = { ...this.state };
      for (const errorKey in inputsValidationErrors) {
        const error = inputsValidationErrors[errorKey];
        const input = updatedStates.inputs[error.param];
        if (input) {
          input.error = error.msg;
        }
      }
      this.setState(updatedStates);
    } else if (errorResponse && errorResponse.length > 0) {
      this.setState({ error: errorResponse });
    }
    this.setState({isLoading: false});
    window.scrollTo(0, 0);
  }

  onSendHandler = (event) => {
    event.preventDefault();   //Evitar refresh na página
    this.setState({error: ""});

    const formData = new FormData();
    let hasErrorOccured = false;
    const inputs = { ...this.state.inputs };
    for (const inputName in inputs) {
      if (inputName === 'birthday') {
        if (moment(inputs.birthday.inputvalue, 'DD/MM/YYYY').isValid()) {
          formData.append('birthday', moment(inputs.birthday.inputvalue, 'DD/MM/YYYY').format());
        } else {
          inputs[inputName].error = "Data de Nascimento Inválida";
          hasErrorOccured = true;
        }
      } else if (inputName === 'password' || inputName === 'confirmPassword') {   //Verificação de senha
        if(this.state.didPasswordChanged ) { //verificação se o campo é obrigatório
          if(inputs[inputName].inputvalue.length === 0) {  //Se o campo é obrigatório, verificar os requisitos
            inputs[inputName].error = "Campo Obrigatório";
            hasErrorOccured = true;
          } else {
            formData.append(inputName, inputs[inputName].inputvalue);
            inputs[inputName].error = "";
          }
        } 
      } else {
        formData.append(inputName, inputs[inputName].inputvalue);
        if (inputs[inputName].inputvalue.length === 0) {
          inputs[inputName].error = "Campo Obrigatório";
          hasErrorOccured = true;
        } else {
          inputs[inputName].error = "";
        }
      } 
    }
    if (inputs.password.inputvalue !== inputs.confirmPassword.inputvalue) {
      inputs.confirmPassword.error = "senhas não coincidem";
    }
    if (hasErrorOccured) {
      window.scrollTo(0, 0);
      return this.setState({ inputs: inputs });
    }
    formData.append('image', this.state.imageFile);

    this.setState({isLoading: true});
    if(this.state.isEditing) {
      this.editUser(formData)
    } else {
      this.addNewUser(formData);
    }
  }

  editUser(formData) {
    formData.append('didPasswordChanged', this.state.didPasswordChanged);
    formData.append('id', this.state.userId);
    Axios.put(apiRoutes.putUpdateUser, formData, {
      headers: {'content-type': 'multipart/form-data'}
    }).then(result => {
      this.props.history.push({ pathname: '/admin/users', isLoading: false});
    }).catch(error => {
      this.handleErrors(error);
    });
  }

  addNewUser(formData) {
    Axios.post(apiRoutes.postCreateUser, formData, {
      headers: {'content-type': 'multipart/form-data'}
    }).then(result => {
      this.props.history.push({ pathname: '/admin/users', isLoading: false});
    }).catch(error => {
      this.handleErrors(error);
    });
  }

  render() {
    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;
    return (
      <LoadingOverlay className="content" active={this.state.isLoading} spinner text="Carregando...">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={8}>
              <Card
                content={
                  <form>
                    <h4>Informações Pessoais</h4>
                    <hr/>
                    <FormInputs
                      ncols={["col-md-4", "col-md-8"]}
                      properties={[
                        this.state.inputs.companyId,
                        this.state.inputs.fullName
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.birthday,
                        this.state.inputs.rg,
                        this.state.inputs.cpf
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.tel,
                        this.state.inputs.email,
                        this.state.inputs.userRoleId
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.password,
                        this.state.inputs.confirmPassword
                      ]}
                    />
                    <h4>Informações de Contato</h4>
                    <hr/>
                     <FormInputs
                      ncols={["col-md-10", "col-md-2"]}
                      properties={[
                        this.state.inputs.address,
                        this.state.inputs.cep
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.city,
                        this.state.inputs.neighborhood,
                        this.state.inputs.state
                      ]}
                    />

                    <Button bsStyle="info" pullRight fill type="submit" onClick={this.onSendHandler}>
                      {this.state.isEditing ? 'Atualizar Informações' : 'Cadastrar Usuário'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={4}>
              <UserCard
                bgImage={userBackgroundImage}
                avatar={this.state.imageBlob.length > 0 ? this.state.imageBlob : avatar}
                name= {this.state.inputs.fullName.inputvalue.length === 0 ? "Usuário" : this.state.inputs.fullName.inputvalue}
                userName={this.state.inputs.email.inputvalue.length === 0 ? "usuário@email.com" : this.state.inputs.email.inputvalue}
                description={
                  <span>
                    <br />
                    <input accept=".jpg, .png, .jpeg" type="file" id="file" ref={element => this.browseElement = element} onChange={this.onFileSelected} style={{display: "none"}}/>
                    <Button bsStyle="primary" onClick={this.selectImageHandler}>Anexar Imagem</Button>
                  </span>
                }
              />
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default UserDetails;
