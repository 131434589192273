/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Nav } from "react-bootstrap";
import AuthContext from '../../context/Auth';

class AdminNavbarLinks extends Component {
  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context
 
  logoutHandler = () => {
    this.context.logout();
  }

  render() {
    return (
      <div>
        <Nav pullRight>
          {/*<NavItem eventKey={1} href="#" onClick={this.logoutHandler}>
            Log out
    </NavItem>*/}
          
        </Nav>
      </div>
    );
  }
}

export default AdminNavbarLinks;
