import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "pages/Admin/Admin";
import LoginLayout from "pages/Login/Login"
import AuthContext from './context/Auth';

const App = (props) => {
    const isAuthenticated = useContext(AuthContext).isAuthenticated();
    return ( 
        <BrowserRouter> 
            <Switch>
                { isAuthenticated ? 
                    <Route path="/admin" render={props => <AdminLayout {...props} />} />:
                    <Route exact path='/login' render={props => <LoginLayout {...props} />} />  
                }
                { isAuthenticated ? 
                    <Redirect from="/" to="/admin/dashboard" />:
                    <Redirect from="/" to="/login" />
                }
            </Switch>
        </BrowserRouter>  
    );
}

export default App;