import React, { Component } from 'react';
import AuthContext from '../../context/Auth';
import Axios, { apiRoutes } from '../../Axios';
import LoadingOverlay from "react-loading-overlay";

class Logout extends Component {
    static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context
 
    componentDidMount() {
        Axios.post(apiRoutes.postLogout)
             .then(response => this.context.logout())
             .catch(error => console.log(error));
    }

    render() {
        return( <LoadingOverlay className="content" active={true} spinner text="Carregando..."> </LoadingOverlay> );
    }
}

export default Logout;