var apiKey = "AIzaSyC0Yt3oRUNshlm7CzbVD4tj1mWmby_tDPg";

//########### tables ################
const usersTableHeader = ["Habilitado", "Nome", "Email", "Telefone", "Empresa", "Função"];
const devicesTableHeader = ["Habilitado", "Código do Dispositivo", "Empresa", "Data de fabricação", "Identificação"];
const companiesTablesHeader = ["Habilitado", "Nome", "CNPJ", "Telefone", "Cidade", "Nome do Responsável"];
const scansTableHeader = ["Resultado", "Identificação", "Empresa", "Data de Ocorrência"];
const productsTableHeader = ['Habilitado', 'Nome', 'Data de Cadastro', 'Adicionado Por']

// ############## dashboard's charts ###################
var optionsSales = { // Data for Line Chart
  showArea: false,
  height: "245px",
  axisX: {
    showGrid: false
  },
  lineSmooth: true,
  showLine: true,
  showPoint: true,
  fullWidth: true,
  chartPadding: {
    right: 50
  }
};

var responsiveSales = [
  [
    "screen and (max-width: 640px)",
    {
      axisX: {
        labelInterpolationFnc: function(value) {
          return value[0];
        }
      }
    }
  ]
];

module.exports = {
  usersTableHeader,
  companiesTablesHeader,
  devicesTableHeader,
  productsTableHeader,
  scansTableHeader,
  optionsSales,
  responsiveSales,
  apiKey
};