import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { usersTableHeader } from "variables/Variables.jsx";
import Axios, { apiRoutes } from '../../Axios';
import Button from "components/CustomButton/CustomButton.jsx";

import AuthContext from 'context/Auth';
import LoadingOverlay from "react-loading-overlay";

class Users extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    users: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: '',
    isLoading: true
  }

  loadData = () => {
    if (!this.state.requireUpdate) return //Evitar Loop 
    this.setState({
      requireUpdate: false
    });

    Axios.get(apiRoutes.getUsers, {
      params: {
        companyId: this.context.getCompanyId(),
        page: this.state.currentPage
      }
    })
      .then(response => {
        const users = response.data.users.map(user => {
          return [
            user.active,
            user.id,
            user.fullName,
            user.email,
            user.tel,
            user.company.name,
            user.user_role.name
          ];
        });
        this.setState({ users: users, pages: response.data.pages, isLoading: false });
      }).catch(error => {
        this.setState({ error: error.response.data.message || '', isLoading: false});
      });
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate() {
    this.loadData();
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage + 1,
        requireUpdate: true
      }
    });
  }

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage - 1,
        requireUpdate: true
      }
    });
  }

  onUserSelectedHandler(event, id) {
    if (event.target.tagName === 'TD') { //Só aceita eventos do componente td
      this.props.history.push({ pathname: '/admin/user/' + id });
    }
  }

  createUserHandler = (event) => {
    this.props.history.push({ pathname: '/admin/newUser' });
  }

  userStatusChangedHander = (userIndex, userId) => {
    Axios.put(apiRoutes.putChangeUserStatus, {
      userId: userId,
      active: !this.state.users[userIndex][0]
    }).then(result => {
      const newUserState = [...this.state.users];
      newUserState[userIndex][0] = !newUserState[userIndex][0];
      this.setState({
        users: newUserState
      });
    }).catch(error => {
      if (error.response.data.message) {
        this.setState({ error: error.response.data.message });
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({ error: '' });
        }, 3500);
      }
    });
  }

  render() {

    const style = {
      buttonRow: {
        float: 'right',
        margin: '0 15px',
        marginBottom: '15px',
      },
      spacing: {
        margin: '0 15px'
      },
      tr: {
        cursor: 'pointer'
      }
    }

    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;

    return (
      <LoadingOverlay className="content" active={this.state.isLoading} spinner text="Carregando...">
        <Grid fluid>
          {ErrorElement}
          <Row >
            <div style={style.buttonRow}>
              <Button bsStyle="info" pullRight fill type="submit" onClick={this.createUserHandler}>
                Cadastrar Usuário
              </Button>
            </div>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                title="Usuários Cadastrados"
                category="Registro das usuários cadastrados"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {usersTableHeader.map((prop, key) => {
                          return <th key={'th' + key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.users.length > 0 ?
                        this.state.users.map((user, userIndex) => {
                          return (
                            <tr style={style.tr} onClick={event => this.onUserSelectedHandler(event, user[1])} key={'tr' + user[1] + userIndex}>
                              {user.map((userInfo, infoIndex) => {
                                if (infoIndex === 0) {
                                  return <td key={'td' + user[1] + userIndex + infoIndex}>
                                    <Checkbox
                                      number={"checkbox" + user[1] + userIndex + infoIndex}
                                      isChecked={userInfo}
                                      onChange={() => this.userStatusChangedHander(userIndex, user[1])}
                                      onClick={event => event.stopPropagation()}
                                    />
                                  </td>
                                } else if (infoIndex > 1) { //pular o Id
                                  return <td key={user[1] + userIndex + infoIndex}>{userInfo}</td>;
                                }
                              })}
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item disabled={this.state.currentPage <= 1} style={{ fontSize: "1.2em" }} onClick={this.onPreviousPageHandler}>{'\u2b9c'} Anterior</Pager.Item>
              {' ' + this.state.currentPage + ' / ' + this.state.pages + ' '}
              <Pager.Item disabled={this.state.currentPage >= this.state.pages} style={{ fontSize: "1.2em" }} onClick={this.onNextPageHandler}>Próximo {'\u2b9e'}</Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default Users;
