import React, { Component } from "react";
import Logo from "../../assets/img/login.png";
import classes from "./Login.module.css";
import { Grid, Row } from "react-bootstrap";
import Axios from "../../Axios";
import AuthContext from "../../context/Auth";
import LoadingOverlay from "react-loading-overlay";

class SignIn extends Component {
  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    email: "",
    password: "",
    error: "",
    isLoading: false
  };

  signInHandler = event => {
    event.preventDefault();
    this.setState({ isLoading: true });
    Axios.post("/auth/login", {
      email: this.state.email,
      password: this.state.password
    })
      .then(result => {
        if (result.data.userRoleId >= 2) {
          this.context.login(result.data);
        } else {
          this.setState({
            error: "Sem Permissão",
            isLoading: false
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error.response.data.message ? error.response.data.message : "Login Inválido", //Se estiver erro de validação, vai mostrar Login inválido
          isLoading: false
        });
      });
  };

  inputChangedhandler = (event, key) => {
    this.setState({ [key]: event.target.value });
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner text="Autenticando...">
        <div className={classes.Container}>
          <Grid fluid>
            <Row>
              <img src={Logo} alt="Ciclopack logo" />
            </Row>
            <Row>
              <form onSubmit={this.signInHandler}>
                <label>Sistema Ciclopack</label>
                {this.state.error && (
                  <p className={classes.Error}>{this.state.error}</p>
                )}
                <input
                  type="email"
                  placeholder="Endereço de e-mail"
                  onChange={event => this.inputChangedhandler(event, "email")}
                />
                <input
                  type="password"
                  placeholder="Senha"
                  onChange={event =>
                    this.inputChangedhandler(event, "password")
                  }
                />
                <button type="submit" disabled={this.state.isLoading}>
                  ENTRAR
                </button>
                <hr />
              </form>
            </Row>
          </Grid>
        </div>
      </LoadingOverlay>
    );
  }
}

export default SignIn;
