import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import Axios, { apiRoutes } from '../../Axios';
import Moment from 'moment';
import LoadingOverlay from "react-loading-overlay";
import AuthContext from 'context/Auth';

class CompanyDetails extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  constructor(props, context) {
    super(props, context);
    const productId = this.props.match.params.id;
    console.log(this.context.getUserRole());
    this.state = {
      productId: productId,
      isLoading: true,
      error: "",
      inputs: {
        productName: {
          label: "Produto",
          type: "text",
          bsClass: "form-control",
          placeholder: "Nome completo",
          error: "",
          inputvalue: "",
          disabled: true,
          onChange: (event) => this.onInputChangedHandler(event, 'productName'),
        },
        registerDate: {
          label: "Data de Cadastro",
          type: "text",
          bsClass: "form-control",
          placeholder: "DD/MM/YYYY",
          defaultValue: "",
          inputvalue: "",
          disabled: true,
          onChange: (event) => this.onInputChangedHandler(event, 'registerDate')
        },
        minWaveLength: {
          label: "Comprimento Mínimo de Onda",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: this.context.getUserRole() < 3,
          onChange: (event) => this.onInputChangedHandler(event, 'minWaveLength')
        },
        maxWaveLength: {
          label: "Comprimento Máximo de Onda",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: this.context.getUserRole() < 3,
          onChange: (event) => this.onInputChangedHandler(event, 'maxWaveLength')
        },
        deviation: {
          label: "Desvio",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: this.context.getUserRole() < 3,
          onChange: (event) => this.onInputChangedHandler(event, 'deviation')
        },
        addedBy: {
          label: "Cadastrado por",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: true,
          onChange: (event) => this.onInputChangedHandler(event, 'addedBy')
        },
        companyName: {
          label: "Empresa",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: true,
          onChange: (event) => this.onInputChangedHandler(event, 'companyName')
        },
        infraredValue: {
          label: "Valor do sensor",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: "",
          disabled: true,
          onChange: (event) => this.onInputChangedHandler(event, 'infraredValue')
        },
      }
    }
  }

  componentDidMount() {
    Axios.get(apiRoutes.getProductDetails, {
      params: {
        productId: this.state.productId
      }
    }).then(result => {
      const updatedInputs = { ...this.state.inputs };
      updatedInputs.productName.inputvalue = result.data.name;
      updatedInputs.registerDate.inputvalue = Moment(new Date(result.data.createdAt)).format("DD/MM/YYYY HH:mm");
      updatedInputs.minWaveLength.inputvalue = result.data.minWaveLength.toString();
      updatedInputs.maxWaveLength.inputvalue = result.data.maxWaveLength.toString();
      updatedInputs.deviation.inputvalue = result.data.deviation.toString();
      updatedInputs.addedBy.inputvalue = result.data.user.addedBy;
      updatedInputs.companyName.inputvalue = result.data.company.companyName;
      updatedInputs.infraredValue.inputvalue = result.data.infraredValue;
      this.setState({ updatedInputs, isLoading: false });
    }).catch(error => {
      this.setState({ error: error.response.data.message || '', isLoading: false });
    });
  }

  onInputChangedHandler = (event, inputName) => {
    const updatedStates = { ...this.state };
    updatedStates.inputs[inputName].inputvalue = event.target.value;
    updatedStates.inputs[inputName].error = "";  //limpar o erro anterior quando edita o campo
    this.setState(updatedStates);
  }

  onSendHandler = (event) => {
    event.preventDefault(); //prevenir que o click fazer refresh na página
    let hasErrorOccured = false;
    const inputs = { ...this.state.inputs };
    const inputsValue = {}; //Valores a serem enviados
    for (const inputName in inputs) {
      inputsValue[inputName] = inputs[inputName].inputvalue
      if (inputs[inputName].inputvalue.length === 0) {
        inputs[inputName].error = "Campo Obrigatório";
        hasErrorOccured = true;
      } else {
        inputs[inputName].error = "";
      }
    }
    if (hasErrorOccured) {
      window.scrollTo(0, 0);
      return this.setState({ inputs: inputs });
    }
    this.setState({ isLoading: true });
    Axios.put(apiRoutes.putUpdateProduct, {
      productId: this.state.productId,
      minWaveLength: parseInt(inputsValue.minWaveLength),
      maxWaveLength: parseInt(inputsValue.maxWaveLength),
      deviation: parseFloat(inputsValue.deviation),
    })
      .then(result => {
        this.props.history.push({ pathname: '/admin/products' });
      }).catch(error => {
        this.handleErrors(error)
      });
  }

  onDeleteHandler = (event) => {
    event.preventDefault(); //prevenir que o click fazer refresh na página
    
    confirmAlert({
      
      message: 'Confirma a exclusão deste produto?',
      buttons: [
        {
          label: 'Sim',
          onClick: this.deleteProduct
        },
        {
          label: 'Não'
        }
      ]
    });
  }

  deleteProduct = () => {
    this.setState({ isLoading: true });
    Axios.delete(apiRoutes.deleteProduct, {
      params: {
        productId: this.state.productId
      }
    })
    .then(result => {
        this.props.history.push({ pathname: '/admin/products' });
    }).catch(error => {
        this.handleErrors(error)
    });
  }


  handleErrors(error) {
    const inputsValidationErrors = error.response.data.errors;
    const errorResponse = error.response.data.message;
    if (inputsValidationErrors && inputsValidationErrors.length > 0) {
      const updatedStates = { ...this.state };
      for (const errorKey in inputsValidationErrors) {
        const error = inputsValidationErrors[errorKey];
        const input = updatedStates.inputs[error.param];
        if (input) {
          input.error = error.msg;
        }
      }
      this.setState(updatedStates);
    } else if (errorResponse && errorResponse.length > 0) {
      this.setState({ error: errorResponse });
    }
    this.setState({ isLoading: false });
    window.scrollTo(0, 0);
  }

  render() {
    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;
    return (
      <LoadingOverlay className="content" active={this.state.isLoading} spinner text="Carregando...">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col>
              <Card
                content={
                  <form>
                    <h4>Informações do Produto</h4>
                    <hr />
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      properties={[
                        this.state.inputs.productName,
                        this.state.inputs.companyName,
                        this.state.inputs.addedBy,
                        this.state.inputs.registerDate,
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.minWaveLength,
                        this.state.inputs.maxWaveLength,
                        this.state.inputs.deviation
                      ]}
                    />
                    <h4>Informações Avançadas</h4>
                    <hr />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        this.state.inputs.infraredValue,
                      ]}
                    />
                    {this.context.getUserRole() === 3 ?
                      <>
                      <Button bsStyle="info" pullRight fill type="submit" onClick={this.onSendHandler}>
                        {'Aplicar'}
                      </Button>
                      <Button bsStyle="danger" pullRight fill type="submit" onClick={this.onDeleteHandler} style={{marginRight: '50px'}}>
                      {'Remover Produto'}
                    </Button> 
                    </>: null
                    }
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default CompanyDetails;
