import React from 'react';
import PropTypes from 'prop-types';
import GoogleMapReact from 'google-map-react';
import { apiKey } from '../../variables/Variables.jsx';

const GoogleMap = ({ children, ...props }) => (
  <main style={{width: '100%', height: '100%'}}>
    <GoogleMapReact
      bootstrapURLKeys={{
        key: apiKey,
      }}
      {...props}
    >
      {children}
    </GoogleMapReact>
  </main>
);

GoogleMap.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

GoogleMap.defaultProps = {
  children: null,
};

export default GoogleMap;
