import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { companiesTablesHeader } from "variables/Variables.jsx";
import Axios, { apiRoutes } from '../../Axios';
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import AuthContext from 'context/Auth';
import Button from "components/CustomButton/CustomButton.jsx";
import LoadingOverlay from "react-loading-overlay";

class Companies extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    companies: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: '',
    isLoading: true,
  }

  loadDate = () => {
    if (!this.state.requireUpdate) return //Evitar Loop 
    this.setState({
      requireUpdate: false
    });

    Axios.get(apiRoutes.getCompanies, {
      params: {
        companyId: this.context.getCompanyId(),
        page: this.state.currentPage
      }
    }).then(response => {
      const companies = response.data.companies.map(company => {
        return [
          company.active,
          company.id,
          company.name,
          company.cnpj,
          company.tel,
          company.city,
          company.company_responsable.fullName
        ];
      });
      this.setState({ companies: companies, pages: response.data.pages, isLoading: false });
    }).catch(error => {
      this.setState({ error: error.response.data.message || '', isLoading: false});
    });
  }

  componentDidMount() {
    this.loadDate();
  }

  componentDidUpdate() {
    this.loadDate();
  }

  addNewCompany = (event) => {
    this.props.history.push({ pathname: '/admin/addCompany' });
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage + 1,
        requireUpdate: true
      }
    });
  }

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage - 1,
        requireUpdate: true
      }
    });
  }

  companyStatusChangedHander = (companyIndex, companyId) => {
    Axios.put(apiRoutes.putChangeCompanyStatus, {
      companyId: companyId,
      active: !this.state.companies[companyIndex][0]
    }).then(result => {
      const newCompanyState = [...this.state.companies];
      newCompanyState[companyIndex][0] = !newCompanyState[companyIndex][0];
      this.setState({
        companies: newCompanyState
      });
    }).catch(error => {
      if (error.response.data.message) {
        this.setState({ error: error.response.data.message });
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({ error: '' });
        }, 3500);
      }
    });
  }

  onCompanySelectedHandler(event, id) {
    if (event.target.tagName === 'TD') { //Só aceita eventos do componente td
      this.props.history.push({ pathname: '/admin/company/' + id });
    }
  }

  render() {
    const style = {
      buttonRow: {
        float: 'right',
        margin: '0 15px',
        marginBottom: '15px',
      },
      spacing: {
        margin: '0 15px'
      },
      tr: {
        cursor: 'pointer'
      }
    }

    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;

    return (
      <LoadingOverlay className="content" active={this.state.isLoading} spinner text="Carregando...">
        <Grid fluid>
          {ErrorElement}
          <Row >
            <div style={style.buttonRow}>
              <Button bsStyle="info" pullRight fill type="submit" onClick={this.addNewCompany}>
                Cadastrar Empresa
              </Button>
            </div>
          </Row>

          <Row>
            <Col md={12}>
              <Card
                title="Empresas Cadastradas"
                category="Registro das empresas cadastradas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {companiesTablesHeader.map((prop, key) => {
                          return <th key={'th' + key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.companies.length > 0 ?
                        this.state.companies.map((company, companyIndex) => {
                          return (
                            <tr style={style.tr} onClick={(event) => this.onCompanySelectedHandler(event, company[1])} key={'tr' + companyIndex}>
                              {company.map((companyInfo, infoKey) => {
                                if (infoKey === 0) {    //active
                                  return <td key={'td' + company[1]}> <Checkbox
                                    number={"checkbox" + company[1]}
                                    isChecked={companyInfo}
                                    onChange={(event) => this.companyStatusChangedHander(companyIndex, company[1])}
                                    onClick={event => event.stopPropagation()}
                                  /></td>
                                } else if (infoKey !== 1) { //id
                                  return <td key={company[1] + infoKey}>{companyInfo}</td>;
                                } else {
                                  return null;  //No caso da informação não exibida, não mostrar mensagem de aviso
                                }
                              })}
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item disabled={this.state.currentPage <= 1} style={{ fontSize: "1.2em" }} onClick={this.onPreviousPageHandler}>{'\u2b9c'} Anterior</Pager.Item>
              {' ' + this.state.currentPage + ' / ' + this.state.pages + ' '}
              <Pager.Item disabled={this.state.currentPage >= this.state.pages} style={{ fontSize: "1.2em" }} onClick={this.onNextPageHandler}>Próximo {'\u2b9e'}</Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default Companies;
