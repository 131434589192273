import React, { Component } from "react";
import { FormGroup, ControlLabel, FormControl, Row, HelpBlock,DropdownButton,MenuItem,Dropdown } from "react-bootstrap";

function getSelectedName(id, values) {
  let selectedName = "Administrador";   //Valor inicial, caso não acha (exemplo: nível 3 do usuário)
  values.forEach(element => {
    if(element.id === id)  selectedName = element.name; 
  });
  return selectedName;
}

function FieldGroup({ label, ...props }) {
  let value = (props.inputvalue && props.inputvalue.length > 0) ? {value: props.inputvalue} : {};  //Input EditText
  return (
    <FormGroup>
      <ControlLabel style = {props.error ? {color: 'red'}: null}>{label}</ControlLabel>
      {props.type !== 'dropdown' ?
      <FormControl {...value} style = {props.error ? {borderColor: 'red'}: null} {...props} /> : 
      <DropdownButton
                      id={label}
                      bsStyle={(props.error ? 'Danger' : 'default') +  ' btn-block'}
                      title={props.inputvalue !== "" ? getSelectedName(props.inputvalue, props.values) : "Selecione " + label}
                      key={props.index}
                    >
                      <Dropdown.Menu style={{ maxHeight: "200px",  overflowY: 'scroll', display: 'block' }}>
                      {
                        props.values.map((value, index) => <MenuItem key={'CompanyDropDownItem ' + index} eventKey={index} onSelect={props.onItemSelected}>{value.name}</MenuItem>)
                      }
                      </Dropdown.Menu>
                    </DropdownButton>
                    }
      {props.error ? <HelpBlock style={{color:'red'}}>{props.error}</HelpBlock> : null}
    </FormGroup>
  );
}

export class FormInputs extends Component {
  render() {
    var row = [];
    for (var i = 0; i < this.props.ncols.length; i++) {
      row.push(
        <div key={i} className={this.props.ncols[i]}>
          <FieldGroup {...this.props.properties[i]} index={i}/>
        </div>
      );
    }
    return <Row>{row}</Row>;
  }
}

export default FormInputs;
