import React, { Component } from 'react';
import AuthContext from './Auth';
import Axios from '../Axios';

//CLASSE WRAPPER PARA GERENCIAR CONTEXT
class Provider extends Component {
    state = {
        access_token: '',
        expiresAt: '',
        userRoleId: -1,
        companyId: -1,
        companyName: ''
    }

    isAuthenticated = () => {
        let authenticated = false;
        if (this.state.access_token === '') {   //Obter do locaStorage Atualiza os state e causa atualização
            this.setState({
                access_token: localStorage.getItem('access_token'),
                expiresAt: localStorage.getItem('expiresAt'),
                userRoleId: +localStorage.getItem('userRoleId'),
                companyId: +localStorage.getItem('companyId'),
                companyName: localStorage.getItem('companyName')
            });
            this.setupAxios(localStorage.getItem('access_token'));
        }
        authenticated = this.state.access_token !== '' && new Date(this.state.expiresAt).getTime() > new Date().getTime();

        return authenticated;
    }

    setupAxios = (token) => {
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+ token;   //Token de autorização
        Axios.interceptors.response.use(response => {       
            return response; //Retornar o response para ser tratado nas outras páginas
        }, error => {        
            if (error.response.status === 401) {
                this.logout();
            }
            return Promise.reject(error);      //Encaminhar o erro para ser tratado nas outras páginas
        });
    }

    login = (data) => {
        this.setupAxios(data.access_token)
        let companyId = -1;
        companyId = data.userRoleId > 0 && data.userRoleId < 3 ? data.companyId : companyId;
        localStorage.setItem("access_token", data.access_token);
        localStorage.setItem("expiresAt", data.expiresAt);
        localStorage.setItem("userRoleId", data.userRoleId);
        localStorage.setItem('companyId', companyId);
        localStorage.setItem('companyName', data.companyName);
        this.setState({
            access_token: data.access_token,
            expiresAt: data.expiresAt,
            userRoleId: data.userRoleId,
            companyId: companyId,
            companyName: data.companyName
        });
    }

    logout = () => {
        localStorage.clear();
        this.setState({
            access_token: '',
            expiresAt: '',
            userRoleId: -1
        });
    }

    setCompanyId = (id) => {
        this.setState({
            companyId: id
        });
    }

    getCompanyId = () => {
        return this.state.companyId;
    }

    getUserRole = () => {
        return this.state.userRoleId;
    }

    getToken = () => {
        return this.state.access_token;
    }

    isCiclopackUser = () => {
        return this.state.userRoleId === 3;
    }

    setCompanyName = (name) => {
        this.setState({
            companyName: name
        });
    }

    getCompanyName = () => {
        return this.state.companyId === -1 ? 'Todas as Empresas' : this.state.companyName;
    }

    render() {
        return (
            <AuthContext.Provider value={{
                state: this.state,
                isAuthenticated: this.isAuthenticated,
                login: this.login,
                logout: this.logout,
                getUserRole: this.getUserRole,
                getToken: this.getToken,
                isCiclopackUser: this.isCiclopackUser,
                setCompanyId: this.setCompanyId,
                getCompanyId: this.getCompanyId,
                getCompanyName: this.getCompanyName,
                setCompanyName: this.setCompanyName
            }}>
                {this.props.children}     
            </AuthContext.Provider>       
        );
    }
}

export default Provider;