import React from 'react';
 //Valores iniciais, onde serão definidos no provider
const authContext = React.createContext({
    access_token: "",     
    expiresAt: "",
    userRoleId: -1,
    companyId: -1,
    companyName: "",
    setCompanyId: (id) => {},
    isAuthenticated: () => {},
    login: () => {},
    logout: () => {},
    getUserRole: () => {},
    getToken: () => {},
    isCiclopackUser: () => {},
    getCompanyId: () => {},
    getCompanyName: () => {}
});

export default authContext;