import React from 'react';
import { Route } from "react-router-dom";

import Dashboard from "containers/Dashboard/Dashboard.jsx";
import UserDetails from "containers/UserDetails/UserDetails";
import CompanyDetails from 'containers/CompanyDetails/CompanyDetails.jsx';
import Users from "containers/Users/Users.jsx";
import Occurrences from "containers/Occurrences/Occurrences.jsx";
import Companies from "containers/Companies/Companies.jsx";
import Traceability from "containers/Traceability/Traceability.jsx";
import Devices from 'containers/Devices/Devices.jsx';
import OccurrenceDetails from 'containers/OccurrenceDetails/OccurrenceDetails.jsx';
import Logout from 'containers/Logout/Logout.js';
import CompanySelection from 'containers/CompanySelection/CompanySelection.jsx';
import Products from 'containers/Products/Products.jsx';
import ProductDetails from 'containers/ProductDetails/ProductDetails.jsx';

const allRoutes = {
  dashboard: {
    path: "/dashboard",
    name: "DASHBOARD",
    icon: "pe-7s-graph",
    component: Dashboard
  },
  users: {
    path: "/users",
    name: "USUÁRIOS",
    icon: "pe-7s-user",
    component: Users,
  },
  editUser: {
    path: "/user/:id",
    name: "Editar Informações do usuário",
    icon: "pe-7s-user",
    component: UserDetails,
  },
  newUser: {
    path: "/newUser",
    name: "CADASTRAR USUÁRIO",
    icon: "pe-7s-user",
    component: UserDetails
  },
  newCompany: {
    path: "/addCompany",
    name: "CADASTRAR EMPRESA",
    icon: "pe-7s-user",
    component: CompanyDetails
  },
  occurrences: {
    path: "/occurrences",
    name: "CONSULTAR OCORRÊNCIAS",
    icon: "pe-7s-note2",
    component: Occurrences
  },
  occurrenceDetails: {
    path: "/occurrence/:id",
    name: "CONSULTAR OCORRÊNCIAS",
    icon: "pe-7s-note2",
    component: OccurrenceDetails
  },
  devices: {
    path: "/dispositivos",
    name: "DISPOSITIVOS",
    icon: "pe-7s-pendrive",
    component: Devices
  },
  traceability: {
    path: "/traceability",
    name: "RASTREABILIDADE",
    icon: "pe-7s-map-marker",
    component: Traceability
  },
  companies: {
    path: "/companies",
    name: "EMPRESAS",
    icon: "pe-7s-portfolio",
    component: Companies,
  },
  companyDetails: {
    path: "/company/:id",
    name: "EMPRESAS",
    icon: "pe-7s-portfolio",
    component: CompanyDetails,
  },
  companySelection: { 
    path: "/companySelect",
    name: "Seleção de Empresa",
    icon: "pe-7s-back",
    component: CompanySelection
  },
  products: { 
    path: "/products",
    name: "Produtos",
    icon: "pe-7s-box2",
    component: Products
  },
  productDetails: { 
    path: "/product/:id",
    name: "Informações do Produto",
    icon: "pe-7s-box2",
    component: ProductDetails
  },
  logout: { 
    path: "/logout",
    name: "Logout",
    icon: "pe-7s-back",
    component: Logout
  }
};

const companiesRoutes = {
  dashboard: allRoutes.dashboard,
  users: allRoutes.users,
  newUser: allRoutes.newUser,
  editUser: allRoutes.editUser,
  occurrences: allRoutes.occurrences,
  occurrenceDetails: allRoutes.occurrenceDetails,
  devices: allRoutes.devices,
  traceability: allRoutes.traceability,
  products: allRoutes.products,
  productDetails: allRoutes.productDetails,
  logout: allRoutes.logout
};

const companiesNavigationSideBarItems = {
  dashboard: allRoutes.dashboard,
  users: allRoutes.users,
  products: allRoutes.products,
  occurrences: allRoutes.occurrences,
  devices: allRoutes.devices,
  traceability: allRoutes.traceability,
  logout: allRoutes.logout
}

const ciclopackNavigationSideBarItems = {
  dashboard: allRoutes.dashboard,
  companies: allRoutes.companies,
  users: allRoutes.users,
  products: allRoutes.products,
  occurrences: allRoutes.occurrences,
  devices: allRoutes.devices,
  traceability: allRoutes.traceability,
  logout: allRoutes.logout
}

const objToArray = (obj) => {
    return Object.keys(obj).map(key => {
      return obj[key];
    })
}

export const getRoutes = (isCiclopackUser) => {
  let routes = isCiclopackUser ? allRoutes : companiesRoutes;
  routes = objToArray(routes);
  return routes.map((prop, key) => {
    return (
      <Route
        path={'/admin'+prop.path}
        exact
        render={props => (
          <prop.component
            {...props}
          />
        )}
        key={key}
      />
    );
  });
};

export const getSideBarRoutes = (isCiclopackUser) => {
  let routes = isCiclopackUser ? ciclopackNavigationSideBarItems : companiesNavigationSideBarItems
  routes = objToArray(routes); 
  return routes;
}

  //Motrar nome da página no Top Navigation Bar 
export const getRouteName = path => {
    const routes = objToArray(allRoutes);
    for (let i = 0; i < routes.length; i++) {
      if (
        path.indexOf(
            routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Ciclopack";
}
