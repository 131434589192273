import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { productsTableHeader } from "variables/Variables.jsx";
import Axios, { apiRoutes } from '../../Axios';
import Moment from 'moment';

import AuthContext from 'context/Auth';

class Products extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    products: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: ''
  }

  loadData = () => {
    if (!this.state.requireUpdate) return //Evitar Loop 
    this.setState({
      requireUpdate: false,
      page: this.state.currentPage
    });

    Axios.get(apiRoutes.getProducts, {
      params: {
        companyId: this.context.getCompanyId(),
        page: this.state.currentPage
      }
    }).then(response => {
      const products = response.data.products.map(product => {
        return [
          product.active,
          product.id,
          product.name,
          Moment(new Date(product.createdAt)).format("DD/MM/YYYY HH:mm"),
          product.user.addedBy,
        ];
      });
      this.setState({ products: products, pages: response.data.pages });
    }).catch(error => {
      this.setState({ error: error.response.data.message || ''});
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        requireUpdate: true,
        currentPage: prevState.currentPage + 1
      }
    });
  }

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        requireUpdate: true,
        currentPage: prevState.currentPage - 1
      }
    });
  }

  productStatusChangedHander = (productIndex, productId) => {
    Axios.put(apiRoutes.putChangeProductStatus, {
      productId: productId,
      active: !this.state.products[productIndex][0]
    }).then(result => {
      const newProductState = [...this.state.products];
      newProductState[productIndex][0] = !newProductState[productIndex][0];
      this.setState({
        products: newProductState
      });
    }).catch(error => {
      if (error.response.data.message) {
        this.setState({ error: error.response.data.message });
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({ error: '' });
        }, 3500);
      }
    });
  }

  onProductSelectedHandler(event, id) {
    if (event.target.tagName === 'TD') { //Só aceita eventos do componente td
      this.props.history.push({ pathname: '/admin/product/' + id });
    }
  }

  render() {
    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;

    return (
      <div className="content">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={12}>
              <Card
                title="Products Cadastrados"
                category="Registro dos productos cadastrados"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {productsTableHeader.map((prop, key) => {
                          return <th key={'th' + key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.products.length > 0 ?
                        this.state.products.map((product, productIndex) => {
                          return (
                            <tr key={'tr' + product[1]} onClick={(event) => this.onProductSelectedHandler(event, product[1])}>
                              {product.map((productInfo, infoKey) => {
                                if (infoKey === 0) {
                                  return <td key={'td' + product[1] + productIndex}>
                                    <Checkbox
                                      number={"checkbox" + product[1] + productIndex}
                                      isChecked={productInfo}
                                      onChange={() => this.productStatusChangedHander(productIndex, product[1])}
                                      onClick={event => event.stopPropagation()}
                                    />
                                  </td>
                                } else if (infoKey > 1) {  //Pular id
                                  return <td key={infoKey + product[1] + productIndex}>{productInfo}</td>;
                                } else {
                                  return null;  //No caso da informação não exibida, não mostrar mensagem de aviso
                                }
                              })}
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item disabled={this.state.currentPage <= 1} style={{ fontSize: "1.2em" }} onClick={this.onPreviousPageHandler}>{'\u2b9c'} Anterior</Pager.Item>
              {' ' + this.state.currentPage + ' / ' + this.state.pages + ' '}
              <Pager.Item disabled={this.state.currentPage >= this.state.pages} style={{ fontSize: "1.2em" }} onClick={this.onNextPageHandler}>Próximo {'\u2b9e'}</Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Products;
