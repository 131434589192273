import React from "react";
import ReactDOM from 'react-dom';

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/sass/_light-bootstrap-dashboard-react.scss?v=1.3.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";

import App from './App'
import AuthProvider from './context/AuthProvider';


ReactDOM.render(<AuthProvider><App /></AuthProvider>,  document.getElementById("root"));
