import React, { Component } from "react";
import ChartistGraph from "react-chartist";
import { Grid, Row, Col } from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { OccurrenceRow } from "components/Rows/OccurrenceRow.jsx";
import { optionsSales, responsiveSales } from "variables/Variables.jsx";

import Axios, { apiRoutes } from "../../Axios";
import moment from "moment";
import AuthContext from "context/Auth";
import LoadingOverlay from "react-loading-overlay";

class Dashboard extends Component {
  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    companyName: "",
    activeUsers: 0,
    totalUsers: 0,
    lastestOccurrences: [],
    occurrencesGraph: [],
    succesScansPercentage: 0,
    totalOccurrences: 0,
    totalScans: 0,
    error: "",
    isLoading: true,
  };

  componentDidMount() {
    Axios.get(apiRoutes.getDashboard, {
      params: {
        companyId: this.context.getCompanyId(),
      },
    })
      .then((response) => {
        this.setState({ ...response.data, isLoading: false });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message || "",
          isLoading: false,
        });
      });
  }

  createLegend() {
    var legend = []; //Poder ter mais do que 1 legenda
    var type = "fa fa-circle text-info"; //"info", "danger", "warning"
    legend.push(<i key={"legend" + 1} className={type} />);
    legend.push(" ");
    legend.push(this.state.companyName);
    return legend;
  }

  listLast12Months = () => {
    var monthsName = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    const months = [];
    var d = new Date();
    var i;
    for (i = 0; i <= 12; i++) {
      months.push(
        monthsName[d.getMonth()] + "/" + d.getFullYear().toString().substr(-2)
      );
      d.setMonth(d.getMonth() - 1);
    }
    return months.reverse(); //Reverte a ordem do array (crescente)
  };

  loadTableData = () => {
    var monthsName = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];
    var tableData = {
      labels: this.listLast12Months(),
      series: [[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]],
    };
    this.state.occurrencesGraph.forEach((occurrence) => {
      //Para achar indice procurar mesAbreviado/Ano(YY)
      const index = tableData.labels.indexOf(
        monthsName[occurrence.month - 1] +
          "/" +
          occurrence.year.toString().substr(-2)
      );
      tableData.series[0][index] = occurrence.count;
    });
    return tableData;
  };

  onRowSelectedHandler = (event, id) => {
    this.props.history.push({ pathname: "/admin/occurrence/" + id });
  };

  render() {
    const style = {
      table: {
        height: "344px",
        overflow: "auto",
      },
    };
    const tableData = this.loadTableData();

    const ErrorElement =
      this.state.error.length > 0 ? (
        <Row>
          <p style={{ color: "red" }}>{this.state.error}</p>
        </Row>
      ) : null;

    return (
      <LoadingOverlay
        className="content"
        active={this.state.isLoading}
        spinner
        text="Carregando..."
      >
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-users text-info" />}
                statsText="Usuários Ativos"
                statsValue={
                  this.state.activeUsers + "/" + this.state.totalUsers
                }
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-wallet text-success" />}
                statsText="Embalagens Escaneadas"
                statsValue={this.state.totalScans}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="pe-7s-graph1 text-danger" />}
                statsText="Ocorrências"
                statsValue={this.state.totalOccurrences}
              />
            </Col>
            <Col lg={3} sm={6}>
              <StatsCard
                bigIcon={<i className="fa pe-7s-graph1 text-info" />}
                statsText="Sucesso de verificações"
                statsValue={this.state.succesScansPercentage + "%"}
              />
            </Col>
          </Row>
          <Row>
            <Col md={8}>
              <Card
                statsIcon="fa fa-history"
                id="chartHours"
                title="Embalagens Escaneadas"
                category="Últimos 12 meses"
                stats={
                  "Última atualização: " +
                  moment(new Date()).format("DD/MM/YYYY hh:mm")
                }
                content={
                  <div className="ct-chart">
                    <ChartistGraph
                      data={tableData}
                      type="Line"
                      options={optionsSales}
                      responsiveOptions={responsiveSales}
                    />
                  </div>
                }
                legend={<div className="legend">{this.createLegend()}</div>}
              />
            </Col>
            <Col md={4}>
              <Card
                title="Últimas Ocorrências"
                category="Registro das últimas 10 ocorrências"
                stats={
                  "Última atualização: " +
                  moment(new Date()).format("DD/MM/YYYY hh:mm")
                }
                content={
                  <div className="table-full-width" style={style.table}>
                    {" "}
                    {/* className={"table-full-width"} style={{height:'344px', overflow: 'auto'}} */}
                    <table className="table">
                      <OccurrenceRow
                        onSelect={this.onRowSelectedHandler}
                        tasksData={this.state.lastestOccurrences}
                      />
                    </table>
                  </div>
                }
              />
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default Dashboard;
