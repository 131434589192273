/*!

=========================================================
* Light Bootstrap Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from "react";
import { Navbar, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";
import AuthContext from '../../context/Auth';

class Header extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  constructor(props) {
    super(props);
    this.state = {
      sidebarExists: false
    };
  }
  mobileSidebarToggle = (e) => {
    if (this.state.sidebarExists === false) {
      this.setState({
        sidebarExists: true
      });
    }
    e.preventDefault();
    document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function() {
      this.parentElement.removeChild(this);
      document.documentElement.classList.remove("nav-open");
    };
    document.body.appendChild(node);
  }

  onCompanySelectionHandler = (event) => {
    if (this.context.getUserRole() === 3) this.props.history.push({pathname: "/admin/companySelect"})
  }

  render() {
    const style = {
      buttonRow: {
        float: 'right',
        margin: '0 15px',
        marginBottom: '15px',
      },
      spacing: {
        margin: '0 15px'
      },
      dropDown: {
        backgroundColor: '#89c240',
        color:'#ffffff',
        borderColor: '#89c240',
      }
    }

    return (
      <Navbar fluid>
        <Navbar.Header>
          <Navbar.Brand>
            <p style={{marginTop:'10px'}}>{this.props.pageName}</p>
          </Navbar.Brand>
          <Navbar.Toggle onClick={this.mobileSidebarToggle} />
        </Navbar.Header>
        <Button bsStyle="info" type="submit" onClick={this.onCompanySelectionHandler} style={style.dropDown}>
                {this.context.getCompanyName()}
          </Button>
        <Navbar.Collapse>
          <AdminNavbarLinks />
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withRouter(Header);
