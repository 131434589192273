import Axios from 'axios';

/*
baseURL: 'http://localhost:8080/'
baseURL: 'https://ciclopack.azurewebsites.net'
*/

//Estas configurações sobreescrevem as configurações globais
const instance = Axios.create({
    baseURL: 'https://ciclopack.azurewebsites.net'
});   

//Configurações do token e interceptors são feitos no App.js

//Rotas do API
const apiRoutes = {
    getDashboard: '/dashboard/get_info',
    getProducts: '/products/get_products',
    getProductDetails: '/products/get_product_details',
    getScans: '/scans/get_results',
    getScanDetails: '/scans/get_result_details',
    getScanImage: '/scans/get_result_image',
    getDevices: '/devices/get_devices',
    getUsers: '/users/get_users',
    getUserDetails: '/users/get_user_details',
    getUserImage: '/users/get_user_image',
    getCompanies: '/company/get_companies',
    getCompaniesList: '/company/get_companies_list',
    getCompanyDetails: '/company/get_company_details',

    postLogin: '/auth/login',
    postLogout: '/auth/logout',
    postCreateUser: '/users/create_user',
    postCreateCompany: '/company/add_company',

    putUpdateDevice: '/devices/update_info',
    putChangeDeviceStatus: '/devices/device_status',
    putUpdateUser: '/users/update_user',
    putChangeUserStatus: '/users/user_status',
    putChangeCompanyStatus: '/company/company_status',
    putUpdateCompanyInfo: '/company/update_company',
    putChangeProductStatus: '/products/product_status',
    putUpdateProduct: '/products/update_product',

    deleteUser: '/users/delete_user',
    deleteProduct: '/products/delete_product'
}

export{apiRoutes}
export default instance;