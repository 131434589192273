import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { devicesTableHeader } from "variables/Variables.jsx";
import Axios, { apiRoutes } from '../../Axios';
import Moment from 'moment';

import AuthContext from 'context/Auth';

class Devices extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    devices: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: ''
  }

  loadData = () => {
    if (!this.state.requireUpdate) return //Evitar Loop 
    this.setState({
      requireUpdate: false,
      page: this.state.currentPage
    });

    Axios.get(apiRoutes.getDevices, {
      params: {
        companyId: this.context.getCompanyId(),
        page: this.state.currentPage
      }
    }).then(response => {
      const devices = response.data.devices.map(device => {
        return [
          device.active,
          device.id,
          device.sn,
          device.company.name,
          Moment(new Date(device.manufacturedAt)).format("DD/MM/YYYY HH:mm"),
          device.name,
        ];
      });
      this.setState({ devices: devices, pages: response.data.pages });
    }).catch(error => {
      this.setState({ error: error.response.data.message || ''});
    });
  }

  componentDidUpdate() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        requireUpdate: true,
        currentPage: prevState.currentPage + 1
      }
    });
  }

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        requireUpdate: true,
        currentPage: prevState.currentPage - 1
      }
    });
  }

  deviceStatusChangedHander = (deviceIndex, deviceId) => {
    Axios.put(apiRoutes.putChangeDeviceStatus, {
      deviceId: deviceId,
      active: !this.state.devices[deviceIndex][0]
    }).then(result => {
      const newDeviceState = [...this.state.devices];
      newDeviceState[deviceIndex][0] = !newDeviceState[deviceIndex][0];
      this.setState({
        devices: newDeviceState
      });
    }).catch(error => {
      if (error.response.data.message) {
        this.setState({ error: error.response.data.message });
        window.scrollTo(0, 0);
        setTimeout(() => {
          this.setState({ error: '' });
        }, 3500);
      }
    });
  }

  render() {
    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;

    return (
      <div className="content">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={12}>
              <Card
                title="Dispositivos Cadastrados"
                category="Registro das dispositivos cadastrados"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {devicesTableHeader.map((prop, key) => {
                          return <th key={'th' + key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.devices.length > 0 ?
                        this.state.devices.map((device, deviceIndex) => {
                          return (
                            <tr key={'tr' + device[1]}>
                              {device.map((deviceInfo, infoKey) => {
                                if (infoKey === 0) {
                                  return <td key={'td' + device[1] + deviceIndex}>
                                    <Checkbox
                                      number={"checkbox" + device[1] + deviceIndex}
                                      isChecked={deviceInfo}
                                      onChange={() => this.deviceStatusChangedHander(deviceIndex, device[1])}
                                    />
                                  </td>
                                } else if (infoKey > 1) {  //Pular id
                                  return <td key={infoKey + device[1] + deviceIndex}>{deviceInfo}</td>;
                                } else {
                                  return null;  //No caso da informação não exibida, não mostrar mensagem de aviso
                                }
                              })}
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item disabled={this.state.currentPage <= 1} style={{ fontSize: "1.2em" }} onClick={this.onPreviousPageHandler}>{'\u2b9c'} Anterior</Pager.Item>
              {' ' + this.state.currentPage + ' / ' + this.state.pages + ' '}
              <Pager.Item disabled={this.state.currentPage >= this.state.pages} style={{ fontSize: "1.2em" }} onClick={this.onNextPageHandler}>Próximo {'\u2b9e'}</Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Devices;
