import React, { Component } from "react";
import { Grid, Row, Col, Carousel } from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import noImagebkg from "../../assets/img/carousel.png";
import Marker from "../../components/Maps/Marker";
import GoogleMap from "../../components/Maps/GoogleMap";

import moment from "moment";
import Axios, { apiRoutes } from "../../Axios";
import LoadingOverlay from "react-loading-overlay";

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: this.props.match.params.id,
      isLoading: true,
      isValidProduct: false,
      label: "",
      appVersion: "",
      locationLat: null,
      locationLong: null,
      scannedAt: "",
      createdAt: "",
      error: "",
      user: {
        fullName: "",
      },
      company: {
        name: "",
      },
      device: {
        name: "",
        sn: "",
      },
      images: [],
    };
  }

  componentDidMount() {
    Axios.get(apiRoutes.getScanDetails, {
      params: {
        resultId: this.state.id,
      },
    })
      .then((result) => {
        this.setState(result.data); //Primeiro setar os valores para depois retornar promise
        if (result.data.images && result.data.images.length > 0) {
          return Promise.all(
            result.data.images.map((image) => {
              return Axios.get(apiRoutes.getScanImage, {
                responseType: "arraybuffer",
                params: {
                  imageId: image.id,
                },
              });
            })
          );
        }
      })
      .then((imagesResponse) => {
        console.log(this.state.isValidProduct);
        let images = [];
        if (imagesResponse) {
          images = imagesResponse.map((imageResponse) => {
            if (imageResponse && imageResponse.data) {
              const base64 = btoa(
                new Uint8Array(imageResponse.data).reduce(
                  (data, byte) => data + String.fromCharCode(byte),
                  ""
                )
              );
              return "data:;base64," + base64;
            } else {
              return ""; //Imagem falhou para carregar
            }
          });
        }
        this.setState({ images, isLoading: false });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message || "",
          isLoading: false,
        });
      });
  }

  bindResizeListener = (map, maps, bounds) => {
    //Recentralizar quando redimensionar a tela
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
        map.setZoom(15);
      });
    });
  };

  apiIsLoaded = (map, maps) => {
    //Centralizar o mapa
    const bounds = new maps.LatLngBounds();
    bounds.extend(
      new maps.LatLng(this.state.locationLat, this.state.locationLong)
    );
    map.fitBounds(bounds);
    map.setZoom(15);
    this.bindResizeListener(map, maps, bounds); //Listener para quando redimensionar a tela, centralizar o mapa
  };

  render() {
    const ErrorElement =
      this.state.error.length > 0 ? (
        <Row>
          <p style={{ color: "red" }}>{this.state.error}</p>
        </Row>
      ) : null;
    console.log(this.state.isValidProduct);
    const result = this.state.isValidProduct ? "Válido" : "Inválido";
    console.log(result);
    return (
      <LoadingOverlay
        className="content"
        active={this.state.isLoading}
        spinner
        text="Carregando..."
      >
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={7}>
              <Card
                title={"Ocorrência #" + this.state.id}
                content={
                  <form>
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        {
                          label: "Identificação",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "Identificação da Ocorrência",
                          inputvalue: this.state.label,
                          disabled: true,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-5", "col-md-3", "col-md-4"]}
                      properties={[
                        {
                          label: "Resultado",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: result,
                          disabled: true,
                        },
                        {
                          label: "Operador",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: this.state.user.fullName,
                          disabled: true,
                        },
                        {
                          label: "Empresa",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: this.state.company.name,
                          disabled: true,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "SN do dispositivo",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: this.state.device.sn,
                          disabled: true,
                        },
                        {
                          label: "Label do dispositivo",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: this.state.device.name,
                          disabled: true,
                        },
                        {
                          label: "Versão(App)",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue: this.state.appVersion,
                          disabled: true,
                        },
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        {
                          label: "Data de Verificação",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue:
                            this.state.scannedAt.length > 0
                              ? moment(new Date(this.state.scannedAt)).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "",
                          disabled: true,
                        },
                        {
                          label: "Data de Envio",
                          type: "text",
                          bsClass: "form-control",
                          placeholder: "",
                          inputvalue:
                            this.state.scannedAt.length > 0
                              ? moment(new Date(this.state.createdAt)).format(
                                  "DD/MM/YYYY HH:mm"
                                )
                              : "",
                          disabled: true,
                        },
                      ]}
                    />

                    <Row>
                      <Col md={12}>
                        <Carousel>
                          {this.state.images.length > 0 ? (
                            this.state.images.map((image) => (
                              <Carousel.Item>
                                <img
                                  width={900}
                                  height={500}
                                  alt="900x500"
                                  src={image}
                                />
                              </Carousel.Item>
                            ))
                          ) : (
                            <Carousel.Item>
                              <img
                                width={900}
                                height={500}
                                alt="900x500"
                                src={noImagebkg}
                              />
                              <Carousel.Caption>
                                <h3>Sem Imagens</h3>
                              </Carousel.Caption>
                            </Carousel.Item>
                          )}
                        </Carousel>
                      </Col>
                    </Row>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
            <Col md={5}>
              <Card
                title={"Localização da Ocorrência #" + this.state.id}
                content={
                  <Row>
                    <section style={{ width: "100%", height: "60vh" }}>
                      {this.state.locationLat ? (
                        <GoogleMap
                          defaultZoom={8}
                          defaultCenter={[
                            this.state.locationLat || -15.188657,
                            this.state.locationLong || -54.2979841,
                          ]}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) =>
                            this.apiIsLoaded(map, maps)
                          }
                        >
                          <Marker
                            text={"Localização"}
                            lat={this.state.locationLat}
                            lng={this.state.locationLong}
                          />
                        </GoogleMap>
                      ) : null}
                    </section>
                  </Row>
                }
              />
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default CreateUser;
