import React, { Component } from "react";
import { Switch } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar";
import Footer from "components/Footer/Footer";
import Sidebar from "components/Sidebar/Sidebar";

import {getRoutes, getSideBarRoutes, getRouteName} from "routes.js";
import AuthContext from '../../context/Auth'

class Admin extends Component {

  static contextType = AuthContext;

  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
    //Quando Muda de página faz o scroll para top
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  render() {
    const isCiclopackUser = this.context.isCiclopackUser();
    return (
      <div className="wrapper">
        <Sidebar {...this.props} routes={getSideBarRoutes(isCiclopackUser)} />
        <div id="main-panel" className="main-panel" ref="mainPanel">
          <AdminNavbar
                pageName={getRouteName(this.props.location.pathname)}
          />
          <Switch>{getRoutes(isCiclopackUser)}</Switch>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Admin;
