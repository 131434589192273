import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
} from "react-bootstrap";
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import Axios, { apiRoutes } from '../../Axios';
import moment from 'moment';
import LoadingOverlay from "react-loading-overlay";

class CompanyDetails extends Component {

  constructor(props) {
    super(props);
    const isEditing = this.props.match.path === "/admin/company/:id";
    const companyId = this.props.match.params.id;

    this.state = {
      isEditing: isEditing,
      companyId: companyId,
      isLoading: isEditing,
      error: "",
      inputs: {
        responsableFullName: {
          label: "Nome Completo",
          type: "text",
          bsClass: "form-control",
          placeholder: "Nome completo",
          error: "",
          autoFocus: true,
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableFullName'),
        },
        responsableBirthday: {
          label: "Data de Nascimento",
          type: "text",
          bsClass: "form-control",
          placeholder: "DD/MM/YYYY",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableBirthday')
        },
        responsableRg: {
          label: "RG",
          type: "text",
          bsClass: "form-control",
          placeholder: "RG",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableRg')
        },
        responsableCpf: {
          label: "CPF",
          type: "text",
          bsClass: "form-control",
          placeholder: "CPF",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableCpf')
        },
        responsableTel: {
          label: "Telefone",
          type: "text",
          bsClass: "form-control",
          placeholder: "(dd) xxxxx-xxxx",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableTel')
        },
        responsableEmail: {
          label: "Email",
          type: "email",
          bsClass: "form-control",
          placeholder: "Email",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableEmail')
        },
        responsableAddress: {
          label: "Endereço",
          type: "text",
          bsClass: "form-control",
          placeholder: "Rua José Paulino, 615",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableAddress')
        },
        responsableCep: {
          label: "CEP",
          type: "text",
          bsClass: "form-control",
          placeholder: "CEP",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableCep')
        },
        responsableCity: {
          label: "Cidade",
          type: "text",
          bsClass: "form-control",
          placeholder: "Cidade",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableCity')
        },
        responsableNeighborhood: {
          label: "Bairro",
          type: "text",
          bsClass: "form-control",
          placeholder: "Bairro",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableNeighborhood')
        },
        responsableState: {
          label: "Estado",
          type: "text",
          bsClass: "form-control",
          placeholder: "Estado",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'responsableState')
        },
        name: {
          label: "Razão Social",
          type: "text",
          bsClass: "form-control",
          placeholder: "Razão Social",
          inputvalue: "",
          disabled: isEditing,
          onChange: (event) => this.onInputChangedHandler(event, 'name')
        },
        cnpj: {
          label: "CNPJ",
          type: "text",
          bsClass: "form-control",
          placeholder: "CNPJ",
          defaultValue: "",
          inputvalue: "",
          disabled: isEditing,
          onChange: (event) => this.onInputChangedHandler(event, 'cnpj')
        },
        tel: {
          label: "Telefone",
          type: "text",
          bsClass: "form-control",
          placeholder: "(dd) xxxxx-xxxx",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'tel')
        },
        address: {
          label: "Endereço",
          type: "text",
          bsClass: "form-control",
          placeholder: "Rua José Paulino, 615",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'address')
        },
        cep: {
          label: "CEP",
          type: "text",
          bsClass: "form-control",
          placeholder: "CEP",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'cep')
        },
        city: {
          label: "Cidade",
          type: "text",
          bsClass: "form-control",
          placeholder: "Cidade",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'city')
        },
        neighborhood: {
          label: "Bairro",
          type: "text",
          bsClass: "form-control",
          placeholder: "Bairro",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'neighborhood')
        },
        state: {
          label: "Estado",
          type: "text",
          bsClass: "form-control",
          placeholder: "Estado",
          defaultValue: "",
          inputvalue: "",
          onChange: (event) => this.onInputChangedHandler(event, 'state')
        },
        tokenExpirationMillis: {
          label: "Expiração do token (Horas)",
          type: "text",
          bsClass: "form-control",
          placeholder: "",
          defaultValue: "",
          inputvalue: isEditing ? "" : "24",
          onChange: (event) => this.onInputChangedHandler(event, 'tokenExpirationMillis')
        }
      }
    }
  }

  componentDidMount() {
    if (this.state.isEditing) {
      Axios.get(apiRoutes.getCompanyDetails, {
        params: {
          companyId: this.state.companyId
        }
      }).then(result => {
        const updatedInputs = { ...this.state.inputs };
        updatedInputs.responsableFullName.inputvalue = result.data.company_responsable.fullName;
        updatedInputs.responsableBirthday.inputvalue = moment(new Date(result.data.company_responsable.birthday)).format("DD/MM/YYYY")
        updatedInputs.responsableRg.inputvalue = result.data.company_responsable.rg;
        updatedInputs.responsableCpf.inputvalue = result.data.company_responsable.cpf;
        updatedInputs.responsableTel.inputvalue = result.data.company_responsable.tel;
        updatedInputs.responsableEmail.inputvalue = result.data.company_responsable.email;
        updatedInputs.responsableAddress.inputvalue = result.data.company_responsable.address;
        updatedInputs.responsableCep.inputvalue = result.data.company_responsable.cep;
        updatedInputs.responsableCity.inputvalue = result.data.company_responsable.city;
        updatedInputs.responsableNeighborhood.inputvalue = result.data.company_responsable.neighborhood;
        updatedInputs.responsableState.inputvalue = result.data.company_responsable.state;
        updatedInputs.name.inputvalue = result.data.name;
        updatedInputs.cnpj.inputvalue = result.data.cnpj;
        updatedInputs.tel.inputvalue = result.data.tel;
        updatedInputs.address.inputvalue = result.data.address;
        updatedInputs.cep.inputvalue = result.data.cep;
        updatedInputs.city.inputvalue = result.data.city;
        updatedInputs.neighborhood.inputvalue = result.data.neighborhood;
        updatedInputs.state.inputvalue = result.data.state;
        updatedInputs.tokenExpirationMillis.inputvalue = (+result.data.tokenExpirationMillis / 1000 / 60 / 60).toFixed(2);
        this.setState({ updatedInputs, isLoading: false });
      }).catch(error => {
        this.setState({error: error.response.data.message || '', isLoading: false});
      });
    }
  }

  onInputChangedHandler = (event, inputName) => {
    const updatedStates = { ...this.state };
    updatedStates.inputs[inputName].inputvalue = event.target.value;
    updatedStates.inputs[inputName].error = "";  //limpar o erro anterior quando edita o campo
    this.setState(updatedStates);
  }

  onSendHandler = (event) => {
    event.preventDefault(); //prevenir que o click fazer refresh na página
    if (this.state.isEditing) {
      this.sendEditCompany();
    } else {
      this.sendAddCompany();
    }
  }

  sendEditCompany = () => {
    let hasErrorOccured = false;
    const inputs = { ...this.state.inputs };
    const inputsValue = {}; //Valores a serem enviados
    for (const inputName in inputs) {
      if (inputName === 'responsableBirthday') {
        if(moment(inputs.responsableBirthday.inputvalue, 'DD/MM/YYYY').isValid) {
          inputsValue.responsableBirthday = moment(inputs.responsableBirthday.inputvalue, 'DD/MM/YYYY').format();
        } else {
          inputs[inputName].error = 'Data de Nascimento Inválida';
          hasErrorOccured = true;
        }
      } else if (inputName === 'tokenExpirationMillis') {
        if (!isNaN(+inputs.tokenExpirationMillis.inputvalue)) {
          inputsValue.tokenExpirationMillis = +inputs.tokenExpirationMillis.inputvalue * 60 * 60 * 1000;
        } 
      } else {
        inputsValue[inputName] = inputs[inputName].inputvalue
        if (inputs[inputName].inputvalue.length === 0) {
          inputs[inputName].error = "Campo Obrigatório";
          hasErrorOccured = true;
        } else {
          inputs[inputName].error = "";
        }
      } 
    }
    if (hasErrorOccured) {
      window.scrollTo(0, 0);
      return this.setState({ inputs: inputs });
    }
    this.setState({isLoading: true});
    Axios.put(apiRoutes.putUpdateCompanyInfo, {...inputsValue, companyId: this.state.companyId})
         .then(result => {
            this.props.history.push({ pathname: '/admin/companies'});
         }).catch(error => {
           this.handleErrors(error)
         });
  }

  sendAddCompany = () => {
    let hasErrorOccured = false;
    const inputs = { ...this.state.inputs };
    const inputsValue = {}; //Valores a serem enviados
    for (const inputName in inputs) {
      if (inputName === 'responsableBirthday') {
        if(moment(inputs.responsableBirthday.inputvalue, 'DD/MM/YYYY').isValid()) {
          inputsValue.responsableBirthday = moment(inputs.responsableBirthday.inputvalue, 'DD/MM/YYYY').format();
        } else {
          inputs[inputName].error = 'Data de Nascimento Inválida';
          hasErrorOccured = true;
        }
      } else if (inputName === 'tokenExpirationMillis') {
        if (!isNaN(+inputs.tokenExpirationMillis.inputvalue)) {
          inputsValue.tokenExpirationMillis = +inputs.tokenExpirationMillis.inputvalue * 60 * 60 * 1000;
        } 
      } else {
        inputsValue[inputName] = inputs[inputName].inputvalue
        if (inputs[inputName].inputvalue.length === 0) {
          inputs[inputName].error = "Campo Obrigatório";
          hasErrorOccured = true;
        }
      } 
    }
    if (hasErrorOccured) {
      window.scrollTo(0, 0);
      return this.setState({ inputs: inputs });
    }
    this.setState({isLoading: true});
    Axios.post(apiRoutes.postCreateCompany, inputsValue)   //Tirar os inputs
      .then(result => {
        this.props.history.push({ pathname: '/admin/companies'});
      }).catch(error => {
        this.handleErrors(error);
      });
  }

  handleErrors(error) {
    const inputsValidationErrors = error.response.data.errors;
    const errorResponse = error.response.data.message;
    if (inputsValidationErrors && inputsValidationErrors.length > 0) {
      const updatedStates = { ...this.state };
      for (const errorKey in inputsValidationErrors) {
        const error = inputsValidationErrors[errorKey];
        const input = updatedStates.inputs[error.param];
        if (input) {
          input.error = error.msg;
        }
      }
      this.setState(updatedStates);
    } else if (errorResponse && errorResponse.length > 0) {
      this.setState({ error: errorResponse });
    }
    this.setState({isLoading: false});
    window.scrollTo(0, 0);
  }

  render() {
    const ErrorElement = this.state.error.length > 0 ?
      <Row>
        <p style={{ color: 'red' }}>{this.state.error}</p>
      </Row> : null;
    return (
      <LoadingOverlay className="content" active={this.state.isLoading} spinner text="Carregando...">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col>
              <Card
                content={
                  <form>
                    <h4>Informações do Responsável</h4>
                    <hr />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        this.state.inputs.responsableFullName
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.responsableBirthday,
                        this.state.inputs.responsableRg,
                        this.state.inputs.responsableCpf
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.responsableTel,
                        this.state.inputs.responsableEmail
                      ]}
                    />
                    <h4>Informações de Contato do responsável</h4>
                    <hr />
                    <FormInputs
                      ncols={["col-md-10", "col-md-2"]}
                      properties={[
                        this.state.inputs.responsableAddress,
                        this.state.inputs.responsableCep
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-4", "col-md-4", "col-md-4"]}
                      properties={[
                        this.state.inputs.responsableCity,
                        this.state.inputs.responsableNeighborhood,
                        this.state.inputs.responsableState
                      ]}
                    />
                    <h4>Informações da Empresa</h4>
                    <hr />
                    <FormInputs
                      ncols={["col-md-12"]}
                      properties={[
                        this.state.inputs.name
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-4", "col-md-2"]}
                      properties={[
                        this.state.inputs.cnpj,
                        this.state.inputs.tel,
                        this.state.inputs.address,
                        this.state.inputs.cep
                      ]}
                    />
                    <FormInputs
                      ncols={["col-md-3", "col-md-3", "col-md-3", "col-md-3"]}
                      properties={[
                        this.state.inputs.city,
                        this.state.inputs.neighborhood,
                        this.state.inputs.state,
                        this.state.inputs.tokenExpirationMillis
                      ]}
                    />
                    <Button bsStyle="info" pullRight fill type="submit" onClick={this.onSendHandler}>
                      {this.state.isEditing ? 'Atualizar Informações' : 'Cadastrar Empresa'}
                    </Button>
                    <div className="clearfix" />
                  </form>
                }
              />
            </Col>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default CompanyDetails;
