import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import Axios, { apiRoutes } from '../../Axios';
import AuthContext from 'context/Auth';

class Companies extends Component {

  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    companies: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: ''
  }

  loadDate = () => {
    if (!this.state.requireUpdate) return //Evitar Loop 
    this.setState({
      requireUpdate: false
    });

    Axios.get(apiRoutes.getCompaniesList, {
      params: {
        companyId: -1,
        page: this.state.currentPage
      }
    }).then(response => {
      this.setState(response.data);
    }).catch(error => {
      this.setState({error: error.response.data.message || ''});
    });
  }

  componentDidMount() {
    this.loadDate();
  }

  componentDidUpdate() {
    this.loadDate();
  }

  addNewCompany = (event) => {
    this.props.history.push({ pathname: '/admin/addCompany' });
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage + 1,
        requireUpdate: true
      }
    });
  }

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage - 1,
        requireUpdate: true
      }
    });
  }

  onCompanySelectedHandler(event, id, name) {
    this.context.setCompanyId(id);
    this.context.setCompanyName(name);
    this.props.history.goBack();
  }

  render() {
    const styles = {
      tableRows: {textAlign: 'center', verticalAlign: 'middle'},
      tr: {
        cursor: 'pointer'
      }
    }
    const ErrorElement = this.state.error.length > 0 ?
    <Row>
      <p style={{ color: 'red' }}>{this.state.error}</p>
    </Row> : null;

    return (
      <div className="content">
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={12}>
              <Card
                title="Selecione uma Empresa"
                category="Registro das empresas cadastradas"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        <th style={styles.tableRows}>Nome da Empresa</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr style={styles.tr} key={"companyTRT"+0} onClick={(event) => this.onCompanySelectedHandler(event, -1, 'Ciclopack')}>
                          <td style={styles.tableRows}>Todas as Empresas</td>
                      </tr>
                      {this.state.companies.length > 0 ?
                        this.state.companies.map((companyObj, companyIndex) => {
                          return (
                            <tr style={styles.tr} key={"companyTR"+companyIndex} onClick={(event) => this.onCompanySelectedHandler(event, companyObj.id, companyObj.name)}>
                              <td style={styles.tableRows}>{companyObj.name}</td>
                            </tr>
                          );
                        }) : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item disabled={this.state.currentPage <= 1} style={{ fontSize: "1.2em" }} onClick={this.onPreviousPageHandler}>{'\u2b9c'} Anterior</Pager.Item>
              {' ' + this.state.currentPage + ' / ' + this.state.pages + ' '}
              <Pager.Item disabled={this.state.currentPage >= this.state.pages} style={{ fontSize: "1.2em" }} onClick={this.onNextPageHandler}>Próximo {'\u2b9e'}</Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Companies;
