import React, { Component } from "react";
import { Grid, Row, Col, Table, Pager } from "react-bootstrap";

import Card from "components/Card/Card.jsx";
import { scansTableHeader } from "variables/Variables.jsx";
import Axios, { apiRoutes } from "../../Axios";
import Moment from "moment";

import valid from "assets/img/valid.png";
import invalid from "assets/img/invalid.png";

import AuthContext from "context/Auth";
import LoadingOverlay from "react-loading-overlay";

class Occurrences extends Component {
  static contextType = AuthContext; //Precisa ser static e ter o nome contextType e React vai disponibilizar this.context

  state = {
    occurrences: [],
    currentPage: 1,
    pages: 1,
    requireUpdate: true,
    error: "",
    isLoading: true,
  };

  loadData = () => {
    if (!this.state.requireUpdate) return; //Evitar Loop
    this.setState({
      requireUpdate: false,
    });

    Axios.get(apiRoutes.getScans, {
      params: {
        companyId: this.context.getCompanyId(),
        page: this.state.currentPage,
      },
    })
      .then((response) => {
        const occurrences = response.data.scans.map((scan) => {
          return [
            scan.id,
            scan.isValidProduct,
            scan.label,
            scan.company.name,
            Moment(new Date(scan.scannedAt)).format("DD/MM/YYYY HH:mm"),
          ];
        });
        this.setState({
          occurrences: occurrences,
          pages: response.data.pages,
          isLoading: false,
        });
      })
      .catch((error) => {
        this.setState({
          error: error.response.data.message || "",
          isLoading: false,
        });
      });
  };

  componentDidUpdate() {
    this.loadData();
  }

  componentDidMount() {
    this.loadData();
  }

  onNextPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage + 1,
        requireUpdate: true,
      };
    });
  };

  onPreviousPageHandler = (event) => {
    this.setState((prevState, props) => {
      return {
        currentPage: prevState.currentPage - 1,
        requireUpdate: true,
      };
    });
  };

  onRowSelectedHandler = (event, id) => {
    this.props.history.push({ pathname: "/admin/occurrence/" + id });
  };

  render() {
    const ErrorElement =
      this.state.error.length > 0 ? (
        <Row>
          <p style={{ color: "red" }}>{this.state.error}</p>
        </Row>
      ) : null;

    const styles = {
      tr: {
        cursor: "pointer",
      },
    };

    return (
      <LoadingOverlay
        className="content"
        active={this.state.isLoading}
        spinner
        text="Carregando..."
      >
        <Grid fluid>
          {ErrorElement}
          <Row>
            <Col md={12}>
              <Card
                title="Ocorrências"
                category="Registro de ocorrências"
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {scansTableHeader.map((prop, key) => {
                          return <th key={"th" + key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.occurrences.length > 0
                        ? this.state.occurrences.map((prop, key) => {
                            return (
                              <tr
                                style={styles.tr}
                                key={"tr" + key}
                                onClick={(event) =>
                                  this.onRowSelectedHandler(event, prop[0])
                                }
                              >
                                {prop.map((prop, key) => {
                                  return key === 1 ? (
                                    <td key={"td" + key}>
                                      {prop ? (
                                        <img src={valid} alt="valid" />
                                      ) : (
                                        <img src={invalid} alt="invalid" />
                                      )}
                                    </td>
                                  ) : key > 1 ? ( //Pular Id
                                    <td key={"td" + key}>{prop}</td>
                                  ) : null;
                                })}
                              </tr>
                            );
                          })
                        : null}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Pager>
              <Pager.Item
                disabled={this.state.currentPage <= 1}
                style={{ fontSize: "1.2em" }}
                onClick={this.onPreviousPageHandler}
              >
                {"\u2b9c"} Anterior
              </Pager.Item>
              {" " + this.state.currentPage + " / " + this.state.pages + " "}
              <Pager.Item
                disabled={this.state.currentPage >= this.state.pages}
                style={{ fontSize: "1.2em" }}
                onClick={this.onNextPageHandler}
              >
                Próximo {"\u2b9e"}
              </Pager.Item>
            </Pager>
          </Row>
        </Grid>
      </LoadingOverlay>
    );
  }
}

export default Occurrences;
